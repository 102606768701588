



















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({ name: "Button" })
export default class Button extends Vue {
  @Prop({ default: "black" }) type?: string;
  @Prop({ default: "normal" }) size?: string;
  @Prop({ default: false }) block?: boolean;
  @Prop({ default: false }) bordered?: boolean;
  @Prop({ default: "regular" }) text?: string;
  @Prop({ default: false }) disabled?: boolean;
}
