









































import Vue from "vue";
import { Component } from "vue-property-decorator";
import * as data from "@/components/data/projects.json";

interface Project {
  id: number;
  name: string;
  phone: string;
  tel: string;
}

interface Language {
  id: string;
  name: string;
  country: string;
  icon: string;
  isHidden: boolean;
}

@Component({
  name: "project-select",
})
export default class ProjectSelect extends Vue {
  isShown = this.$store.state.showProjectList;
  languages = [
    {
      id: "ru",
      name: "Русский",
      country: "Россия",
      icon: "country_russia",
      isHidden: false,
    },
    {
      id: "en",
      name: "English",
      country: "US",
      icon: "country_us",
      isHidden: false,
    },
    {
      id: "bn",
      name: "বাংলা",
      country: "BD",
      icon: "country_bangladesh",
      isHidden: false,
    },
  ];
  projects = data.paged_projects;

  onChangeProject(project: Project): void {
    this.$store.commit("setProject", project);
    if (this.$store.state.showProjectList) {
      this.$store.commit("hideProjectList");
    }
  }

  get filteredLanguages() {
    return this.languages.filter((x) => !x.isHidden);
  }

  onChangeLanguage(lang: Language): void {
    this.$store.commit("setLanguage", lang);
    if (this.$store.state.showProjectList) {
      this.$store.commit("hideProjectList");
    }
  }

  show(): void {
    if (!this.$store.state.showProjectList) {
      this.$store.commit("displayProjectList");
    }
  }

  hide(): void {
    if (this.$store.state.showProjectList) {
      this.$store.commit("hideProjectList");
    }
  }
}
