interface User {
  id: number;
  is_bot: boolean;
  first_name: string;
  last_name: string;
  username: string;
  language_code: string;
  is_premium: boolean;
}

interface Chat {
  id: number;
  type: "private" | "group" | "supergroup" | "channel";
  title: string;
}

interface Message {
  message_id: number;
  from: User;
  sender_chat: Chat;
  chat: Chat;
}

interface Update {
  update_id: number;
  message: Message;
}

interface Response<T> {
  ok: boolean;
  result: T;
}

export default class Telegram {
  apiKey = "6171662486:AAHWu-2t-cMQtCQr8yh0qFaAQblK7pDl78U";
  uniqueChats: Array<Chat> = [];

  private collectChats(data: Array<Update>): Array<Chat> {
    const chats: Array<Chat> = [];
    data.map((update) => {
      if (update.message) {
        const id = update.message.chat.id;
        if (chats.findIndex((x) => x.id === id) === -1 || chats.length == 0) {
          chats.push(update.message.chat);
        }
      }
    });
    return chats;
  }

  public broadcast(text: string): Promise<Response<Message>> {
    const body = {
      text,
      chat_id: -843291325,
    };
    return fetch(`https://api.telegram.org/bot${this.apiKey}/sendMessage`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => {
      return res.json().then((data) => data as Response<Message>);
    });
  }
}
