import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentProject: {
      id: 527,
      name: "Тюмень",
      phone: "+7 4152 30-11-11",
      tel: "+74152301111",
    },
    currentLanguage: {
      id: "ru",
      name: "Русский",
      country: "Россия",
      icon: "country_russia",
    },
    locate: true,
    showProjectList: false,
    showDocumentationOfferList: false,
    showDocumentationLicenceList: false,
    showDocumentationPrivacyList: false,
    isShownArrow1: false,
    isShownArrow2: false,
    isShownArrow3: false,
  },
  mutations: {
    setProject(context, payload) {
      context.currentProject = payload;
    },
    setLanguage(context, payload) {
      context.currentLanguage = payload;
    },
    toggleLocate(context) {
      context.locate = !context.locate;
    },
    displayProjectList(context) {
      context.showProjectList = true;
    },
    hideProjectList(context) {
      context.showProjectList = false;
    },
  },
  actions: {},
  modules: {},
});
