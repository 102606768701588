









import { Component, Vue } from "vue-property-decorator";
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import Geolocator from "@/components/Geolocator.vue";
import AppBottomMenu from "@/components/AppBottomMenu.vue";

@Component({ components: { AppHeader, AppFooter, Geolocator, AppBottomMenu } })
export default class App extends Vue {}
