







import Vue from "vue";
import { Component } from "vue-property-decorator";
import Translation from "@/components/Translation.vue";

@Component({ name: "app-bottom-menu", components: { i18n: Translation } })
export default class AppBottomMenu extends Vue {}
