



























































import Telegram from "@/utils/Telegram";
import { Component } from "vue-property-decorator";
import Button from "@/components/Button.vue";
import Translation from "@/components/Translation.vue";
import Vue from "vue";

@Component({ components: { Button, Translation } })
export default class Drivers extends Vue {
  botMessage = {
    firstname: null,
    city: null,
    phone: null,
  };
  isAgree = false;

  isRequestSent = false;
  isBlockActive = false;
  hasErrors = false;

  mounted() {
    this.vk_tag();
  }

  vk_tag() {
    //@ts-ignore
    var _tmr = window._tmr || (window._tmr = []);
    _tmr.push({ id: "3403583", type: "pageView", start: new Date().getTime() });
    (function (d, w, id) {
      if (d.getElementById(id)) return;
      var ts = d.createElement("script");
      ts.type = "text/javascript";
      ts.async = true;
      ts.id = id;
      ts.src = "https://top-fwz1.mail.ru/js/code.js";
      //@ts-ignore
      var f = function () {
        var s = d.getElementsByTagName("script")[0];
        //@ts-ignore
        s.parentNode.insertBefore(ts, s);
      };
      //@ts-ignore
      if (w.opera == "[object Opera]") {
        d.addEventListener("DOMContentLoaded", f, false);
      } else {
        f();
      }
    })(document, window, "tmr-code");
  }

  onSend(): void {
    if (
      this.isAgree &&
      this.botMessage.phone !== null &&
      this.botMessage.city !== null &&
      this.botMessage.firstname !== null
    ) {
      const tg = new Telegram();
      const message = `
    Новая завявка\r
    Имя: ${this.botMessage.firstname ?? "Не указано"}\r
    Город: ${this.botMessage.city ?? "Не указано"}\r
    Телефон: ${this.botMessage.phone ?? "Не указано"}\r
    `;
      const response = tg.broadcast(message);
      response.then((res) => {
        this.isRequestSent = true;
        this.hasErrors = !res.ok;
        this.isBlockActive = true;
        setTimeout(() => {
          this.isBlockActive = false;
        }, 30000);
      });
    }
  }
}
