var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'button',
    _vm.type,
    _vm.size,
    _vm.block && 'block',
    _vm.bordered && 'bordered',
    _vm.disabled && 'disabled',
    ("text-" + _vm.text) ],attrs:{"disabled":_vm.disabled,"type":"button","aria-disabled":_vm.disabled}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }