














































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Translation from "@/components/Translation.vue";
import Button from "./Button.vue";

@Component({
  name: "app-footer",
  components: { i18n: Translation, Button },
})
export default class AppFooter extends Vue {
  conversion() {
    //@ts-ignore
    window.gtag_report_conversion();
  }

  goToForm(): void {
    this.$router.push("/callback");
  }

  downloadPage(): void {
    window.open(
      "https://redirect.appmetrica.yandex.com/serve/171331191864127036",
      "_blank"
    );
    //@ts-ignore
    window.gtag_report_conversion();
  }
}
