export class Localizer {
  static getLocalizedImageLink(image: string, language: string): string {
    const ext = image.split(".").pop();
    const folder = ext ? (ext === "svg" ? "svg" : "images") : "images";
    if (image) {
      if (language !== "ru") {
        return `/${folder}/${language}/${image}`;
      }
      return `/${folder}/${image}`;
    }
    return ``;
  }
}
