







































































































































































import Vue from "vue";

export default class LegalInformation extends Vue {
  goToForm(): void {
    this.$router.push("/callback");
  }

  showOffer(): void {
    if (this.$store.state.showDocumentationOfferList === false) {
      this.$store.state.showDocumentationOfferList = true;
      this.$store.state.isShownArrow1 = true;
    }
  }

  hideOffer(): void {
    if (this.$store.state.showDocumentationOfferList === true) {
      this.$store.state.showDocumentationOfferList = false;
      this.$store.state.isShownArrow1 = false;
    }
  }

  showLicence(): void {
    if (this.$store.state.showDocumentationLicenceList === false) {
      this.$store.state.showDocumentationLicenceList = true;
      this.$store.state.isShownArrow2 = true;
    }
  }

  hideLicence(): void {
    if (this.$store.state.showDocumentationLicenceList === true) {
      this.$store.state.showDocumentationLicenceList = false;
      this.$store.state.isShownArrow2 = false;
    }
  }

  showPrivacy(): void {
    if (this.$store.state.showDocumentationPrivacyList === false) {
      this.$store.state.showDocumentationPrivacyList = true;
      this.$store.state.isShownArrow3 = true;
    }
  }

  hidePrivacy(): void {
    if (this.$store.state.showDocumentationPrivacyList === true) {
      this.$store.state.showDocumentationPrivacyList = false;
      this.$store.state.isShownArrow3 = false;
    }
  }
}
