




import { Component, Prop, Vue } from "vue-property-decorator";
import ru from "./data/ru_translation.json";
import en from "./data/en_translation.json";
import bn from "./data/bn_translation.json";

@Component({
  name: "Translation",
})
export default class Translation extends Vue {
  @Prop() phrase?: string;

  languageData: any;

  created() {
    this.loadLangFile();
  }

  get prop() {
    return this.phrase;
  }

  loadLangFile() {
    const language = this.$store.state.currentLanguage.id;
    switch (language) {
      case "ru":
        this.languageData = ru;
        break;
      case "en":
        this.languageData = en;
        break;
      case "bn":
        this.languageData = bn;
        break;
      default:
        this.languageData = ru;
    }
  }

  prepareHtml(value: string): string {
    const result = value.replaceAll("\n", "<br/>");
    return result;
  }

  get translatedString() {
    const language = this.$store.state.currentLanguage.id;
    if (this.phrase) {
      switch (language) {
        case "ru":
          //@ts-ignore
          return this.prepareHtml(ru[this.phrase]);
        case "en":
          //@ts-ignore
          return this.prepareHtml(en[this.phrase]);
        case "bn":
          //@ts-ignore
          return this.prepareHtml(bn[this.phrase]);
        default:
          //@ts-ignore
          return this.prepareHtml(ru[this.phrase]);
      }
    }
    return "";
  }
}
